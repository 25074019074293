import React from 'react';
import PropTypes from 'prop-types';

class ImageSetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.imageSet.name,
      variants: props.imageSet.variants,
      metadataKeys: props.imageSet.metadata_keys
    };
  }

  addVariantRow() {
    let variants = this.state.variants.slice();
    variants.push({ name: '', width: '', height: '' });
    this.setState({ variants: variants });
  }

  removeVariantRow(i) {
    let variants = this.state.variants.slice();
    variants.splice(i, 1);
    this.setState({ variants: variants });
  }

  setVariantAttribute(index, attribute, value) {
    let variants = this.state.variants.slice();
    let variant = variants[index];
    variant[attribute] = value;
    variants[index] = variant;
    this.setState({ variants: variants });
  }

  setMetadataKeysString(string) {
    let keys = string.split(/[ ,;]/);
    this.setState({ metadataKeys: keys });
  }

  metadataKeysString() {
    return this.state.metadataKeys.join(' ');
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2">
            Name
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              name="image_set[name]"
              value={this.state.name}
              onChange={e => this.setState({ name: e.target.value })}
              placeholder="Name"
              className="form-control"
            />
            <small className="muted">
              Only characters, numbers and spaces allowed. (ie. Example Name)
            </small>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2">
            Variants
          </label>
          <div id="variants" className="col-sm-10">
            {this.state.variants.map((variant, i) => {
              return (
                <div className="image-set-variant row" key={i}>
                  <div className="col-sm-5">
                    <input
                      type="hidden"
                      name="image_set[variants][][id]"
                      value={variant.id}
                    />
                    <input
                      type="text"
                      name="image_set[variants][][name]"
                      onChange={e =>
                        this.setVariantAttribute(i, 'name', e.target.value)
                      }
                      value={variant.name}
                      className="name-field form-control form-control-sm"
                      placeholder="Name"
                    />
                  </div>
                  <label htmlFor="dimensions" className="col-sm-2">
                    Width/Height:
                  </label>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      name="image_set[variants][][width]"
                      onChange={e =>
                        this.setVariantAttribute(i, 'width', e.target.value)
                      }
                      value={variant.width}
                      className="width-field form-control form-control-sm"
                      placeholder="Width"
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      type="text"
                      name="image_set[variants][][height]"
                      onChange={e =>
                        this.setVariantAttribute(i, 'height', e.target.value)
                      }
                      value={variant.height}
                      className="height-field form-control form-control-sm"
                      placeholder="Height"
                    />
                  </div>
                  <div className="col-sm-1">
                    <a
                      href="#"
                      className="remove-variant btn btn-outline-secondary btn-sm"
                      onClick={e => this.removeVariantRow(i)}
                    >
                      Remove
                    </a>
                  </div>
                </div>
              );
            })}
            <a
              href="#"
              className="btn btn-success btn-sm"
              onClick={() => this.addVariantRow()}
            >
              Add Another Variant
            </a>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="name" className="col-sm-2">
            Metadata Keys
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              value={this.metadataKeysString()}
              onChange={e => this.setMetadataKeysString(e.target.value)}
              placeholder="Metadata Keys"
            />
            <small className="muted">ie. text_color text_margin</small>
          </div>
        </div>
        <div className="form-group row">
          <div className="offset-sm-2 col-sm-10">
            <button
              type="submit"
              name="commit"
              className="btn btn-primary mr-1"
            >
              Save
            </button>
            <button type="submit" name="commit" className="btn btn-secondary">
              Cancel
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ImageSetForm;
