// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap';

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');

$(document).ready(function() {
  $('.custom-file-input').on('change', function(e) {
    var fileName = e.target.files[0].name;
    $(this)
      .next('.custom-file-label')
      .addClass('selected')
      .html(fileName);
  });
});
// Support component names relative to this directory:
var componentRequireContext = require.context('components', true);
var ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
